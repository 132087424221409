import { config as domainConfig } from '../../../config'

const checkAASA = ({ res, country, path }): boolean => {
  if ('/.well-known/apple-app-site-association' === path) {
    res.setHeader('Content-Type', 'application/json')
    res.end(
      JSON.stringify(
        domainConfig[`${country}`]?.['apple-app-site-association'] ?? {}
      )
    )
    return true
  }
  return false
}

export default checkAASA
