import { EnvType } from '../interfaces/index'
;(function (window) {
  if (window) {
    const _log = window.console.log
    const _error = window.console.error
    const _warn = window.console.warn
    const _info = window.console.info
    const stage = process.env.STAGE || 'prod'
    const trace = (env: EnvType, type: Function, ...args: Array<any>) => {
      let newArgs = [...args]
      if (args.length === 0) {
        newArgs = [env]
      } else if (type.name === 'error') {
        newArgs.unshift(env)
      } else if (
        env !== 'dev' &&
        env !== 'qa' &&
        env !== 'uat' &&
        env !== 'prod'
      ) {
        // if you don't pass the env the log will be dismissed
        return
      }
      // env = 'prod' - shows logs in all environments
      // env = 'uat' - shows logs in all environments but prod
      // env = 'qa' - shows logs in QA and DEV environments
      // env = 'dev' - shows logs only in DEV environment
      if (
        env === 'prod' ||
        type.name === 'error' ||
        stage === env ||
        (env === 'qa' && stage === 'dev') ||
        (env === 'uat' && stage !== 'prod')
      ) {
        type.apply(window.console, newArgs)
      }
    }
    window.console.error = function (env: EnvType, ...args: Array<any>) {
      trace(env, _error, ...args)
    }

    window.console.warn = function (env: EnvType, ...args: Array<any>) {
      trace(env, _warn, ...args)
    }
    window.console.info = function (env: EnvType, ...args: Array<any>) {
      trace(env, _info, ...args)
    }

    if (process.env.IS_LOCAL !== 'true') {
      window.console.log = function (env: EnvType, ...args: Array<any>) {
        trace(env, _log, ...args)
      }
    }
  }
})(process.browser ? window : null)
