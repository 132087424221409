import { IBlock } from '@bees-web/nfa-types'
import { DeferredURL } from '../../interfaces'

export default function getDeferredUrl(
  block: IBlock,
  removeWaitForState = false
): DeferredURL | null {
  const { deferred, waitForState = [] } = block
  const shouldProcess = removeWaitForState ? waitForState.length === 0 : true

  if (deferred && shouldProcess) {
    if (typeof deferred === 'string')
      return {
        url: deferred,
        method: 'get',
      }

    const { url, method = 'get' } = deferred
    return {
      url,
      method: method.toLowerCase(),
    }
  }

  return null
}
