import { VSConfiguration } from '@bees-web/nfa-types'

let _dependencies: NFADependencies | null = null

export interface NFADependencies {
  [key: string]: VSConfiguration
}

export default function getDependencies(
  configs: VSConfiguration[]
): NFADependencies {
  if (!_dependencies) {
    _dependencies = configs.reduce(
      (prev, config) => ({ ...prev, [config.namespace]: config }),
      {}
    )
  }
  return _dependencies
}
