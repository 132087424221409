import { getContext } from '../helpers/getContext'
import { SegmentMethod } from '../interfaces'

export const page: SegmentMethod = async (
  { payload },
  trackId,
  userId,
  anonymousId,
  locale = null
) =>
  globalThis.analytics?.page({
    userId,
    name: payload.name,
    anonymousId,
    properties: { ...payload, trackId },
    context: getContext(locale),
  })
