import anonymizeObject from '../../utils/anonymizeObject'
import cookies from 'js-cookie'

export default async function anonymizeProperties(country: string) {
  const trackOriginal = globalThis.analytics?.track
  const pageOriginal = globalThis.analytics?.page

  const allItems = globalThis.itemsToAnonymize ?? {}

  let itemsToAnonymize = allItems[country]
  if (!itemsToAnonymize) {
    const result = await fetch('/api/security/anonymizedSegmentProperties', {
      headers: {
        country,
      },
    })

    itemsToAnonymize = (await result.json())?.anonymizedSegmentProperties

    globalThis.itemsToAnonymize = {
      ...globalThis.itemsToAnonymize,
      [country]: itemsToAnonymize,
    }
  }

  function anonymizeTrackProperties({
    userId: _userid,
    event,
    anonymousId,
    properties,
    context,
  }) {
    const ajsUserId = cookies.get('ajs_user_id')
    let userId = ajsUserId
    let modifiedProperties = properties

    if (!ajsUserId) {
      if (!anonymousId || !itemsToAnonymize) return
      modifiedProperties = anonymizeObject(properties, itemsToAnonymize)
      userId = anonymousId
    }

    //@ts-expect-error
    return trackOriginal({
      userId,
      event,
      anonymousId,
      properties: modifiedProperties,
      context,
    })
  }

  function anonymizePageProperties({
    userId: _userid,
    name,
    anonymousId,
    properties,
    context,
  }) {
    const ajsUserId = cookies.get('ajs_user_id')
    let modifiedProperties = properties
    let userId = ajsUserId

    if (!ajsUserId) {
      if (!anonymousId || !itemsToAnonymize) return
      modifiedProperties = anonymizeObject(properties, itemsToAnonymize)
      userId = anonymousId
    }

    return pageOriginal({
      userId,
      name,
      anonymousId,
      properties: modifiedProperties,
      context,
    })
  }

  //@ts-expect-error reassigning globalThis.analytics
  globalThis.analytics.track = anonymizeTrackProperties
  //@ts-expect-error reassigning globalThis.analytics
  globalThis.analytics.page = anonymizePageProperties
}
