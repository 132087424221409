import { useEffect } from 'react'

const useOneTrust = (): void => {
  const oneTrustLoaded = typeof window !== 'undefined' && window.OneTrust && window.OneTrust.OnConsentChanged
  if (process.env.STAGE !== 'prod') console.log('OneTrust: loaded')

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.OneTrust &&
      window.OneTrust.OnConsentChanged
    ) {
      window.OneTrust?.OnConsentChanged(async () => {
        if (process.env.STAGE !== 'prod')
          console.log('OneTrust: consent changed')

        const fullUrl = new URL(
          '/api/auth/consent-change',
          window?.location?.origin
        )

        await fetch(fullUrl, {
          method: 'POST',
        })
      })
    }
  }, [oneTrustLoaded])
}

export default useOneTrust
