/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import { createContext, useContext } from 'react'
import { PageContextProps } from '../interfaces'
import { IBlock } from '@bees-web/nfa-types'

export const PageContext = createContext<PageContextProps | null>(null)

export const usePageContext = (): PageContextProps | null =>
  useContext(PageContext)

export const useInteractive = (interactiveId?: string): Partial<IBlock> => {
  const { interactiveMap = {} } = usePageContext()
  if (!interactiveId) return {}
  return interactiveMap[interactiveId] || {}
}
