import { QueryParams } from '../../interfaces'
import { objectToSearchParams } from '../url'

export type JokerResource = 'pages' | 'blocks'

export default function jokerUrlBuilder(
  resource: JokerResource,
  params: QueryParams
): string {
  const { pageName, blockName, ...queryParams } = params

  const queryParam = objectToSearchParams(queryParams).toString()

  return `api/${resource}/${pageName || blockName}?${queryParam}`
}
