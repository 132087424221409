import { QueryParams } from '../../interfaces'

export default function addParamsTo(
  source: URL | URLSearchParams,
  params: QueryParams,
  valueTransformer?: (value: string) => string
): URL | URLSearchParams {
  if (!(source instanceof URLSearchParams) && !(source instanceof URL)) {
    throw new Error('Invalid source provided. Expected URL or URLSearchParams.')
  }

  const urlSearchParams = source instanceof URL ? source.searchParams : source

  for (const [key, value] of Object.entries(params)) {
    if (typeof value !== 'undefined' && value !== null) {
      const transformedValue = valueTransformer
        ? valueTransformer(value)
        : value
      urlSearchParams.set(key, transformedValue)
    }
  }

  return source
}
