import { DynamicVSConfiguration } from '@bees-web/nfa-types'

const coreVS: DynamicVSConfiguration = {
  namespace: 'core',
  redux: () => new Promise(() => ({})),
  pages: {
    '/.well-known/apple-app-site-association': 'core-dummy',
  },
}

export default coreVS
