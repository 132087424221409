//Clear all persisted keys in 'localStorage' that is different than the current BUILD_BUILDID env variable
export const clearPersistStorage = (version: string) => {
  const keys = Object.keys(localStorage)
  const persisted = keys.filter((sl) => sl.includes('persist:'))

  if (persisted.length > 0) {
    const filtered = persisted.filter((reducer) => {
      const storageVersion = Number(reducer.split('-').at(-1))
      const buildVersion = Number(version)

      if (storageVersion !== buildVersion) {
        return reducer
      }
    })

    filtered.forEach((key) => localStorage.removeItem(key))
  }
}
