/* eslint-disable security/detect-object-injection */
import { QueryParams } from '../../interfaces'
import urlToSearchParams from './urlToSearchParams'

export default function urlToObjectParams(url: string): QueryParams {
  const searchParams = urlToSearchParams(url)
  const objectParams: QueryParams = {}

  for (const [key, value] of searchParams.entries()) {
    objectParams[key] = value
  }

  return objectParams
}
