import { Page, Screen } from '../types'

export type GetContext = (locale: string) => {
  locale: string | null
  page: Page
  screen: Screen
  timezone: string | null
  userAgent: string | null
}

export const getContext: GetContext = (locale: string) => {
  const { navigator, location, document, screen } = globalThis || {}
  const { language: browserLanguage = null, userAgent = null } = navigator || {}
  const { title = null, referrer = null } = document || {}
  const { height = null, width = null } = screen || {}
  const {
    pathname: path = null,
    search = null,
    href: url = null,
  } = location || {}

  return {
    locale: locale || browserLanguage,
    page: {
      path,
      referrer,
      search,
      title,
      url,
    },
    screen: {
      height,
      width,
    },
    timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone || null,
    userAgent,
  }
}
