import { ModuleLoader } from '@bees-web/nfa-types'

const getModule = async <T>(loader: ModuleLoader<T>): Promise<T> => {
  if (loader && typeof loader === 'function') {
    const module = await loader()
    return (module.default ? module.default : module) as T
  }

  throw new Error('Invalid Module loader')
}

export default getModule
