import type { NextWebVitalsMetric } from 'next/app'

export const NEXT_NEW_RELIC_METRIC_PREFIX = 'nextJs' as const
export const NEXT_DEFAULT_METRIC_PREFIX = 'Next.js-' as const

/**
 * Transforms a metric name based on the provided label.
 *
 * If the label is 'web-vital', it prepends the metric name with the New Relic prefix (`NEXT_NEW_RELIC_METRIC_PREFIX`).
 *
 * If the label is custom (a custom Next.js metric), it removes the default metric prefix (`NEXT_DEFAULT_METRIC_PREFIX`),
 * capitalizes each word in the metric name, and then prepends it with the New Relic prefix (`NEXT_NEW_RELIC_METRIC_PREFIX`).
 *
 * @param {NextWebVitalsMetric['name']} name - The original metric name.
 * @param {NextWebVitalsMetric['label']} label - The label associated with the metric.
 * @return {string} The transformed metric name.
 */
export const transformMetricName = (
  name: NextWebVitalsMetric['name'],
  label: NextWebVitalsMetric['label']
): string => {
  if (label === 'web-vital') return NEXT_NEW_RELIC_METRIC_PREFIX + name

  const baseMetric = name.slice(NEXT_DEFAULT_METRIC_PREFIX.length)

  const capitalizedWords = baseMetric
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))

  return NEXT_NEW_RELIC_METRIC_PREFIX + capitalizedWords.join('')
}
