/* eslint-disable security/detect-object-injection */
import { configureStore, combineReducers, Reducer } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { storage } from '../utils/createWebStorage'
import segmentTracking from '../segment/middlewares/segment'
import globals, {
  blackList as blacklist,
} from '@bees-web/nfa-interactive-global/reducers'
import webLinkCart from '@bees-web/web-interactive-link-cart/middlewares'
import { ReduxContext } from '@bees-web/nfa-types'
import { setSharedContext } from '../utils/VL/context/sharedContext'
import getReduxContextMap from '../utils/VL/context/getReduxContextMap'

const version = parseInt(process.env.BUILD_BUILDID)

const staticReducers = {
  globals: persistReducer(
    {
      key: `new-web-react-globals-${version}`,
      storage,
      blacklist,
      version,
    },
    globals
  ),
}

const configureReducer = (vsContext: ReduxContext): Reducer => {
  const { reducer, persist } = vsContext.state

  return persist
    ? persistReducer(
        {
          storage,
          ...persist(version),
        },
        reducer
      )
    : reducer
}

const createReducer = (asyncReducers: Record<string, Reducer>): Reducer =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  })

const store = configureStore({
  reducer: createReducer({}),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk, segmentTracking, webLinkCart()),
})

export const loadContextualStore = async (
  modules: string[]
): Promise<Record<string, ReduxContext>> => {
  const loadedModules = await getReduxContextMap(modules)

  setSharedContext(loadedModules)

  const loadedReducers: Record<string, Reducer> = Object.keys(
    loadedModules
  ).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: configureReducer(loadedModules[curr]),
    }),
    {}
  )

  store.replaceReducer(createReducer(loadedReducers))

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore no type available for persistStore
  const persistor = persistStore(store, { manualPersist: false })
  persistor.persist()

  return loadedModules
}

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
