/* eslint-disable security/detect-object-injection */
import { Analytics } from '@segment/analytics-node'

export type InitializeSegment = (
  config: Record<string, { segment: { key: string; host: string } }>
) => void

export const initialize: InitializeSegment = (config) => {
  return Object.keys(config).reduce((results, country) => {
    try {
      const { segment } = config[country]

      if (segment?.key)
        return {
          ...results,
          [country]: new Analytics({
            writeKey: segment.key,
            host: segment.host,
            maxEventsInBatch: 1,
          }),
        }
    } catch ({ message }) {
      console.error(message)
      console.error(`Problem initializing Segment for ${country.toUpperCase()}`)
    }

    return results
  }, {})
}
