/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
export default function simplify(obj: any | any[]): any {
  if (Array.isArray(obj)) {
    const result = []
    for (let i = 0; i < obj.length; i++) {
      const item = simplify(obj[i])
      if (
        item !== null &&
        item !== undefined &&
        !(Array.isArray(item) && item.length === 0) &&
        !(typeof item === 'object' && Object.keys(item).length === 0)
      ) {
        result.push(item)
      }
    }
    return result
  }

  if (typeof obj === 'object' && obj !== null) {
    const cleanedObj: { [key: string]: any } = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          key === '_id' ||
          key === '__v' ||
          key === 'requestTraceId' || // || key === 'locale'
          key === 'serverProcessors'
        ) {
          continue
        }
        const cleanedValue =
          key === 'meta' || key === 'attributes' || key === 'featureFlags'
            ? obj[key]
            : simplify(obj[key])
        if (
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
          !(
            typeof cleanedValue === 'object' &&
            Object.keys(cleanedValue).length === 0
          )
        ) {
          cleanedObj[key] = cleanedValue
        }
      }
    }
    return cleanedObj
  }
  return obj
}
