/* eslint-disable security/detect-object-injection */
import * as GLOBAL_TRACKABLE_ACTIONS from '@bees-web/nfa-interactive-global/constants/trackableActions'
import { IGlobalActions, ReduxContext } from '@bees-web/nfa-types'

let _trackableActions: Set<string> = null
let _publicActions: IGlobalActions = null

export type SetTrackableActions = (modules: Record<string, ReduxContext>) => {
  trackableActions: Set<string>
  publicActions: IGlobalActions
}

export type GetTrackableActions = () => Set<string>

export type GetPublicActions = () => IGlobalActions

export const setSharedContext: SetTrackableActions = (modules) => {
  const actions: IGlobalActions = {}
  const trackableActions: string[] = []

  Object.keys(modules).forEach((key) => {
    const { public: publicActions = {}, trackables = [] } = modules[key].actions
    actions[key] = publicActions
    trackableActions.push(...trackables)
  })
  _publicActions = actions
  _trackableActions = new Set<string>([
    ...Object.values(GLOBAL_TRACKABLE_ACTIONS),
    ...trackableActions,
  ])
  return {
    trackableActions: _trackableActions,
    publicActions: _publicActions,
  }
}

export const getTrackableActions: GetTrackableActions = () =>
  _trackableActions || new Set<string>(Object.values(GLOBAL_TRACKABLE_ACTIONS))

export const getPublicActions: GetPublicActions = () => _publicActions
