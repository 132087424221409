export default function anonymizeObject(obj: any, keyList: string[]): any {
  if (typeof obj !== 'object') return obj
  const filteredProperties: any = {}
  for (const key in obj) {
    const value = obj[key]
    if (typeof value === 'object') {
      filteredProperties[key] = anonymizeObject(value, keyList)
      continue
    }
    const itemToFilter = keyList.find((i) => i === key)
    if (itemToFilter) {
      if (typeof value === 'string') filteredProperties[key] = '****'
      if (typeof value === 'number') filteredProperties[key] = 0
    } else {
      filteredProperties[key] = value
    }
  }
  return filteredProperties
}
