import { getContext } from '../helpers/getContext'
import { SegmentMethod } from '../interfaces'

export const track: SegmentMethod = async (
  { type, payload },
  trackId,
  userId,
  anonymousId,
  locale = null
) =>
  //@ts-ignore
  globalThis.analytics?.track({
    userId,
    event: type,
    anonymousId,
    properties: { ...payload, trackId },
    context: getContext(locale),
  })
