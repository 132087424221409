import { QueryParams } from '../../interfaces'
import addParamsTo from './addParamsTo'

export default function objectToSearchParams(
  source: QueryParams,
  valueTransformer?: (value: string) => string
): URLSearchParams {
  const urlSearchParams = new URLSearchParams()

  addParamsTo(urlSearchParams, source, valueTransformer)

  return urlSearchParams
}
